import { ListedObject, useLazyListObjectsQuery } from '@api/goose/dist/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { SplitLayout } from '@local/split-layout/dist/SplitLayout';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import {
    createXyzInstanceContext,
    XyzContext,
    XyzInstanceContextValue,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import { XyzInstance } from '@local/webviz/dist/types';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store/store';
import { clearSceneState } from 'src/store/visualization/visualizationSlice';
import { isSchemaViewable } from 'src/utils/extractSchema';

import { useObjectFilterParams } from '../../hooks/useObjectFilterParams';
import { useObjectSearchParams } from '../../hooks/useObjectSearchParams';
import { NETWORK_ERROR_DESCR, NETWORK_ERROR_TITLE } from '../../strings';
import { LOAD_SIZE } from '../constants';
import { useColormapLoader } from '../context/hooks/useColormapLoader';
import { ObjectsPanel } from '../ObjectsPanel/ObjectsPanel';
import { SkeletonObjectsPanelContents } from '../ObjectsPanel/ProjectTree/ProjectTreePanel';
import { Plot } from '../Plot/Plot';

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    const params = useParams();
    const { objectName } = useObjectSearchParams();
    const { filters } = useObjectFilterParams();
    const [getObjectByIdTrigger, { isError, isSuccess, isFetching }] = useLazyListObjectsQuery();
    const flags = useFlags();
    const dispatch = useAppDispatch();
    const [gooseObjects, setGooseObjects] = useState<ListedObject[]>([]);

    useColormapLoader();

    useEffect(() => {
        async function fetchGooseData() {
            try {
                const initialResponse = await getObjectByIdTrigger(
                    {
                        orgId: getOrgUuidFromParams(params),
                        workspaceId: getSelectedWorkspaceFromParams(params),
                        ...filters,
                        objectName,
                        offset: 0,
                        limit: LOAD_SIZE,
                    },
                    true,
                ).unwrap();

                const totalObjects = initialResponse.total ?? 0;
                const numberOfRequests = Math.ceil(totalObjects / LOAD_SIZE) - 1;
                const promiseList = Array.from({ length: numberOfRequests }, (_, index) =>
                    getObjectByIdTrigger(
                        {
                            orgId: getOrgUuidFromParams(params),
                            workspaceId: getSelectedWorkspaceFromParams(params),
                            ...filters,
                            objectName,
                            offset: (index + 1) * LOAD_SIZE,
                            limit: LOAD_SIZE,
                        },
                        true,
                    ).unwrap(),
                );

                const responses = await Promise.allSettled(promiseList);

                const objects = responses.reduce<ListedObject[]>((acc, response) => {
                    if (response.status === 'fulfilled') {
                        return [...acc, ...response.value.objects];
                    }
                    trackError('Error loading goose objects for visualization');
                    return acc;
                }, initialResponse.objects);

                setGooseObjects(objects.filter((object) => isSchemaViewable(object.schema, flags)));
            } catch (error) {
                trackError('Error loading goose objects for visualization', JSON.stringify(error));
            }
        }
        fetchGooseData();
        return () => {
            dispatch(clearSceneState());
        };
    }, []);

    const projectTree = (
        <Grid item xs zIndex={1}>
            {xyzInstanceInitialized && isSuccess && <ObjectsPanel data={gooseObjects} />}
            {xyzInstanceInitialized && isFetching && <SkeletonObjectsPanelContents />}
        </Grid>
    );

    const plot = (
        <Plot
            initialized={xyzInstanceInitialized}
            onInitialized={(xyzInstance: XyzInstance) =>
                setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
            }
        />
    );

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            <SplitLayout leftPanelComponent={projectTree} rightPanelComponent={plot} />
        </XyzContext.Provider>
    );
}

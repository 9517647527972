import {
    Breadcrumbs,
    Grid,
    Link,
    Skeleton,
    SkeletonProps,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { FILES, GEOSCIENCE_OBJECTS, RECYCLE_BIN_TITLE } from 'src/strings';

const PATH_TITLE_MAP: { [key: string]: string } = {
    files: FILES,
    overview: GEOSCIENCE_OBJECTS,
    recyclebin: RECYCLE_BIN_TITLE,
};

function getPageTitle(pathname: string) {
    const segment = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
    return PATH_TITLE_MAP[segment] || segment;
}

interface SkeletonContentProps extends React.PropsWithChildren<SkeletonProps> {
    loading: boolean;
}

function SkeletonContent({ children, loading, ...skelton }: SkeletonContentProps) {
    if (loading) {
        return <Skeleton {...skelton} />;
    }
    return children;
}

export interface PageContentProps {
    breadcrumbSegments: { name: string; path?: string }[];
    breadcrumbsLoading?: boolean;
    pageTitle: string;
    pageTitleLoading?: boolean;
    titleActions?: JSX.Element;
    children: React.ReactNode;
    childrenLoading?: boolean;
    childrenLoadingContent?: JSX.Element;
    enablePageTitle?: boolean;
}

export const PageContent = ({
    breadcrumbSegments,
    breadcrumbsLoading = false,
    children,
    pageTitle,
    pageTitleLoading = false,
    titleActions,
    childrenLoading = false,
    childrenLoadingContent,
    enablePageTitle = true,
}: PageContentProps) => {
    const theme = useTheme();
    const location = useLocation();

    const segments = breadcrumbSegments.map(({ name, path }) => (
        <Link
            key={`${name}-${path}`}
            component={RouterLink}
            to={path ?? ''}
            sx={{
                textDecoration: 'none',
                color: theme.palette.grey[700],
                fontSize: 12,
                lineHeight: '14px',
                letterSpacing: '0.13px',
            }}
        >
            {name}
        </Link>
    ));

    return (
        <>
            <SkeletonContent
                width="200px"
                height="14px"
                variant="rectangular"
                loading={breadcrumbsLoading}
            >
                <Breadcrumbs sx={{ flexBasis: 0 }}>{segments}</Breadcrumbs>
            </SkeletonContent>
            <Grid
                container
                direction="row"
                paddingY={theme.spacing(3)}
                columns={{ xs: 1, md: 2 }}
                gap={3}
                alignItems="center"
            >
                <Grid item direction="column" flexGrow={1} flexShrink={0}>
                    <Stack spacing={1}>
                        <SkeletonContent
                            width="100%"
                            height="36px"
                            variant="rectangular"
                            loading={pageTitleLoading}
                        >
                            <Typography component="h1" variant="h3" automation-id="title-row">
                                {pageTitle}
                            </Typography>
                        </SkeletonContent>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                            {enablePageTitle && getPageTitle(location.pathname)}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item>{titleActions}</Grid>
            </Grid>
            <Grid container direction="column">
                {childrenLoading && childrenLoadingContent ? childrenLoadingContent : children}
            </Grid>
        </>
    );
};

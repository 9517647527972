import { ErrorSvg } from '@local/svgs/dist/svg/Error';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import {
    WorkspaceRoleOptionalResponse,
    useUpdateMlEnablementAdminMutation,
} from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { fetchWorkspaces } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TableContainer } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useMemo } from 'react';
import { FormattedDate } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
    ERROR_PAGE_TITLE,
    ERROR_PAGE_SUBTITLE,
    ERROR_PAGE_DESCRIPTION_TEXT_1,
    ERROR_PAGE_DESCRIPTION_TEXT_2,
    ERROR_PAGE_BUTTON,
    TABLE_HEADER_COL_1,
    TABLE_HEADER_COL_2,
    TABLE_HEADER_COL_3,
    NOTIFICATION_MESSAGE_1,
    NOTIFICATION_MESSAGE_2,
} from 'src/strings';

export default function WorkspaceListTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState<string | undefined>(undefined);

    const params = useParams();

    const [updateMLFlag] = useUpdateMlEnablementAdminMutation();

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        pageNumber: number,
    ) => {
        setPage(pageNumber);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const { data, isFetching, isError } = fetchWorkspaces({
        isAdmin: true,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        sort: 'name',
    });

    const workspaces = data?.results ?? [];

    const handleNotificationClose = () => {
        setOpen(false);
    };

    const handleSwitchChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
        workspaceId: string,
    ) => {
        const mlEnabled = event.target.checked;
        const message = mlEnabled ? NOTIFICATION_MESSAGE_1 : NOTIFICATION_MESSAGE_2;

        await updateMLFlag({
            hubUrl: getHubUrlForCurrentOrg(),
            orgId: getOrgUuidFromParams(params),
            mlEnablementRequest: {
                ml_enablements: [{ workspace_id: workspaceId, ml_enabled: mlEnabled }],
            },
        });

        setMessageInfo(message);
        setOpen(true);
    };

    if (isError) {
        return <ErrorMessage />;
    }

    const tableContent = useMemo(
        () => (
            <Stack ml={4} mr={4}>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={data?.links.total || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Show"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        /* To move the pagination to the left */
                        display: 'flex',
                        alignItems: 'left',
                        fontSize: '10px',
                        /* override MUI css style */
                        '& .MuiTablePagination-toolbar': {
                            paddingLeft: '16px',
                        },
                        '& .MuiTablePagination-displayedRows': {
                            fontSize: '10px',
                        },
                    }}
                    slotProps={{
                        select: {
                            IconComponent: ExpandMoreIcon,
                        },
                    }}
                />
                <TableContainer
                    sx={{
                        maxHeight: 'calc(100vh - 250px)',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                    }}
                >
                    <Table aria-label="pagination table for workspaces">
                        <TableHead>
                            <TableRow key="table-header">
                                <TableCell sx={{ minWidth: '300px', fontWeight: '600' }}>
                                    {TABLE_HEADER_COL_1}
                                </TableCell>
                                <TableCell sx={{ fontWeight: '600' }}>
                                    {TABLE_HEADER_COL_2}
                                </TableCell>
                                <TableCell sx={{ fontWeight: '600' }}>
                                    {TABLE_HEADER_COL_3}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetching ? (
                                <TableSkeleton
                                    automation-id="workspace-table-skeleton"
                                    rows={rowsPerPage}
                                    columns={3}
                                />
                            ) : (
                                workspaces.map((row: WorkspaceRoleOptionalResponse) => (
                                    <TableRow key={row.name}>
                                        <TableCell>
                                            <Box>
                                                <Stack direction="row" spacing={2}>
                                                    <Switch
                                                        size="small"
                                                        color="primary"
                                                        checked={row.ml_enabled}
                                                        onChange={(event) =>
                                                            handleSwitchChange(event, row.id)
                                                        }
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            padding: '4px',
                                                        }}
                                                    >
                                                        {row.name}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body2">
                                                    {row.created_by.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body2">
                                                    <FormattedDate
                                                        value={row.updated_at}
                                                        month="short"
                                                        year="numeric"
                                                        day="2-digit"
                                                    />
                                                    <Box
                                                        component="span"
                                                        sx={{ paddingLeft: '4px' }}
                                                    >
                                                        <FormattedDate
                                                            value={row.updated_at}
                                                            hour="numeric"
                                                            minute="numeric"
                                                            hour12={false}
                                                        />
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        ),
        [workspaces, page, rowsPerPage],
    );

    const notification = useMemo(
        () => (
            <Snackbar open={open} autoHideDuration={5000} onClose={handleNotificationClose}>
                <Alert
                    severity="success"
                    variant="standard"
                    onClose={handleNotificationClose}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                >
                    {messageInfo}
                </Alert>
            </Snackbar>
        ),
        [open],
    );
    return (
        <Box>
            {tableContent}
            {notification}
        </Box>
    );
}

function ErrorMessage() {
    return (
        <Box>
            <Stack alignItems="center" pl={4} pr={4} pt={3} pb={3}>
                <ErrorSvg />
            </Stack>
            <Stack alignItems="center">
                <Typography variant="h3" pb={1}>
                    {ERROR_PAGE_TITLE}
                </Typography>
                <Typography variant="h5" pb={2}>
                    {ERROR_PAGE_SUBTITLE}
                </Typography>
                <Typography variant="body1" pb={2} align="center">
                    {ERROR_PAGE_DESCRIPTION_TEXT_1}
                    <br />
                    {ERROR_PAGE_DESCRIPTION_TEXT_2}
                </Typography>
                <Button
                    onClick={() => window.location.reload()}
                    variant="outlined"
                    color="secondary"
                    size="small"
                >
                    {ERROR_PAGE_BUTTON}
                </Button>
            </Stack>
        </Box>
    );
}

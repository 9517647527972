import { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';

import { useSearchInteractionContext } from '../../../../contexts/SearchInteractionContext';
import { useStyles } from './Clustering.styles';
import { ObjectIcon } from './ObjectIcon';

interface ObjectIconGroupProps {
    objects: OrgListedObject[];
    activeObject: OrgListedObject | null;
    onObjectClick?: (objectID: string) => void;
}

export const ObjectIconGroup = ({
    objects,
    activeObject,
    onObjectClick = () => null,
}: ObjectIconGroupProps) => {
    const { classes } = useStyles();
    const { hoveredObject } = useSearchInteractionContext();

    return (
        <div className={classes.groupClusterBase}>
            {objects.map((object) => (
                <ObjectIcon
                    key={object.object_id}
                    schema={object.schema}
                    objectID={object.object_id}
                    hovered={object.object_id === hoveredObject}
                    onClick={() => onObjectClick(object.object_id)}
                    active={object.object_id === activeObject?.object_id}
                    name={object.name.substring(0, object.name.length - 5)}
                />
            ))}
        </div>
    );
};

import { Close } from '@local/web-design-system-2';
import File from '@local/web-design-system-2/dist/icons/File';
import { Divider, Stack, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { UploadStatusIcon } from 'src/components/sidebar/UploadStatusIcon';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { UPLOAD_FILES, UPLOAD_MORE_FILES } from 'src/strings';
import { FileStatus } from 'src/types/files';
import { formatBytes } from 'src/utils/fileUtils';

import { UploadFilesButton } from './UploadFilesButton';

interface Props {
    workspaceId: string;
}

export function ProgressPanel({ workspaceId }: Props) {
    const theme = useTheme();
    const {
        fileUploads,
        failedCount,
        uploadedCount,
        uploadingCount,
        completedCount,
        clearFileUploads,
    } = useFileUploadContainer({ workspaceId });

    return (
        <Stack sx={{ width: '244px', height: 'calc(100vh - 360px)' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                automation-id="uploading-files"
                sx={{ height: '51px', paddingLeft: '24px', paddingRight: '24px' }}
            >
                <Typography variant="body1">{UPLOAD_FILES}</Typography>
                {uploadingCount === 0 && (
                    <IconButton onClick={clearFileUploads} automation-id="close-button">
                        <Close width="20px" height="20px" />
                    </IconButton>
                )}
            </Stack>
            <Divider />
            <Stack
                justifyContent="center"
                automation-id="upload-more-button"
                sx={{ paddingLeft: '24px', paddingRight: '24px', height: '90px' }}
            >
                <UploadFilesButton
                    buttonText={UPLOAD_MORE_FILES}
                    sx={{ height: '36px', whiteSpace: 'nowrap' }}
                    variant="outlined"
                    secondary
                />
            </Stack>
            <Divider />
            <Stack
                spacing={3}
                sx={{ paddingTop: '24px', height: '100%', overflowY: 'auto', my: 2 }}
            >
                {fileUploads.map((file: FileStatus) => (
                    <Stack automation-id="file-upload-container" key={`${file.fileId}`} spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <File color="action" />
                            <Typography
                                variant="body2"
                                title={file.fileName}
                                automation-id="file-upload-file-name"
                                sx={{
                                    flexGrow: 1,
                                    flexBasis: 0,
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {file.fileName}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[500] }}
                                automation-id="file-upload-file-size"
                            >
                                {formatBytes(file.size)}
                            </Typography>
                            <UploadStatusIcon status={file.uploadStatus} />
                        </Stack>
                        <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            automation-id="linear-progress-faked"
                        >
                            <LinearProgress
                                variant="determinate"
                                sx={{ width: '100%' }}
                                value={file.percentCompleted}
                            />
                            {file.percentCompleted > 0 && (
                                <Typography variant="body2" automation-id="percent-completed">
                                    {`${file.percentCompleted.toFixed(0)}%`}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                ))}
            </Stack>
            <Divider />
            {completedCount > 0 && (
                <Grid sx={{ my: 2 }}>
                    {uploadedCount > 0 && (
                        <Grid automation-id="success-notifications">
                            <Alert severity="success">
                                {`${uploadedCount} file${uploadedCount > 1 ? 's' : ''} ${
                                    uploadedCount > 1 ? 'have' : 'has'
                                } been uploaded`}
                            </Alert>
                        </Grid>
                    )}
                    {failedCount > 0 && (
                        <Grid automation-id="failed-notifications">
                            <Alert severity="error">
                                {`${failedCount} file${failedCount > 1 ? 's' : ''} 
                                    ${failedCount > 1 ? 'have' : 'has'} failed to upload`}
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            )}
        </Stack>
    );
}

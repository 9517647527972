import { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { ArrowRight } from '@local/web-design-system-2/dist/icons';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { Fragment, useEffect, useState } from 'react';

import { ObjectIcon, ResultRow } from './ResultRow';
import { useStyles } from './ResultsPanel.styles';

const DEFAULT_SHOW_COUNT = 10;

interface ResultGroupProps {
    expanded: boolean;
    schemaType: string;
    activeObjectID: string;
    objects: OrgListedObject[];
    onChange: (schemaType: string) => void;
    onObjectClick: (objectID: string) => void;
    onObjectHover: (objectID: string, isHovering: boolean) => void;
}

export function ResultGroup({
    schemaType,
    objects,
    expanded,
    onChange,
    onObjectClick,
    onObjectHover,
    activeObjectID,
}: ResultGroupProps) {
    const { classes } = useStyles();
    const [showCounter, setShowCounter] = useState(0);

    const maxObjectsShown = Math.min(
        showCounter * DEFAULT_SHOW_COUNT + DEFAULT_SHOW_COUNT,
        objects.length,
    );

    useEffect(() => {
        if (!expanded) setShowCounter(0);
    }, [expanded]);

    return (
        <Accordion
            disableGutters
            expanded={expanded}
            classes={{ root: classes.accordion }}
            onChange={() => onChange(schemaType)}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                classes={{
                    root: classnames(classes.accordionSummary, {
                        [classes.accordionSummaryExpanded]: expanded,
                    }),
                }}
            >
                <Grid container gap={2} alignItems="center">
                    <ArrowRight
                        className={classnames(classes.chevronBase, {
                            [classes.chevronDown]: expanded,
                        })}
                    />
                    <ObjectIcon schema={objects[0].schema} />
                    <Typography className={classes.groupTitleText}>
                        {schemaType} ({objects.length})
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {objects.slice(0, maxObjectsShown).map((object, index) => (
                    <Fragment key={object.object_id}>
                        <ResultRow
                            object={object}
                            onClick={onObjectClick}
                            onHover={onObjectHover}
                            activeObjectID={activeObjectID}
                        />
                        {index < maxObjectsShown - 1 && <Divider className={classes.itemDivider} />}
                    </Fragment>
                ))}
                {objects.length > maxObjectsShown && (
                    <>
                        <Divider className={classes.itemDivider} />
                        <Grid
                            item
                            gap={1}
                            container
                            wrap="nowrap"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.showMoreRow}
                            onClick={() => setShowCounter(showCounter + 1)}
                        >
                            <Typography variant="body2">Show More</Typography>
                        </Grid>
                    </>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    text: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
    },
    graphic: {
        width: '100%',
        height: '168px',
        backgroundColor: '#FAFAFA',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    highlight: {
        backgroundColor: 'transparent',
    },
}));

import { useBaseXyz } from '@local/webviz/dist/context';
import { HOLE_ID } from '@local/webviz/dist/context/constants';
import type { SelectionKeyValues } from '@local/webviz/dist/types';
import { niceNumber, parsePrefixUid } from '@local/webviz/dist/utilities';

export function useSelectionKeyValues(viewId: string): SelectionKeyValues {
    const getAttributeMetadata = () => {
        const viewEntity = getEntityState(viewId);
        if (viewEntity && 'element' in viewEntity && viewEntity.element) {
            const elementEntity = getEntityState(viewEntity.element);
            if (elementEntity && 'metadata' in elementEntity) {
                const { metadata } = elementEntity;
                if (metadata && 'attributes_metadata' in metadata && metadata.attributes_metadata) {
                    const { attributes_metadata: attributesMetadata } = metadata;
                    return attributesMetadata;
                }
            }
        }
        return {};
    };

    const getAttributeDetails = () => {
        const selectionAttributeData = getSelectionTool().getSelectionAttribute();
        const attributesMetadata = getAttributeMetadata();

        const attributesKeyValues = Object.entries(selectionAttributeData).map(([key, value]) => {
            if (!key || !value) {
                return null;
            }

            if (key === HOLE_ID) {
                return [HOLE_ID, value] as [string, string];
            }
            if (typeof value === 'number') {
                // eslint-disable-next-line no-param-reassign
                value = niceNumber(value);
            }
            const attributeId = parsePrefixUid(key);
            const attributeMetadata = attributesMetadata[attributeId];
            const attributeName = attributeMetadata?.metadata?.name;
            if (!attributeName) {
                return null;
            }

            return [attributeName, value] as [string, string];
        });

        return attributesKeyValues
            .filter((entry) => entry !== null)
            .sort(([keyA], [keyB]) => {
                if (keyA === HOLE_ID) return -1;
                if (keyB === HOLE_ID) return 1;
                return keyA.localeCompare(keyB);
            });
    };

    const { getSelectionTool, getEntityState } = useBaseXyz();
    if (!viewId) {
        return [];
    }

    return getAttributeDetails();
}

import { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { ArrowDown, ArrowUp, Open } from '@local/web-design-system-2/dist/icons';
import { BlockSyncIcon, DriverIcon } from '@local/web-design-system/dist/icons';
import { ListItemIcon, ListItemText, MenuList } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { ID_PARAM, OBJECT_OPEN_IN } from 'src/strings';
import {
    canOpenInBlockSync,
    canOpenInDriver,
    createNavigateToBlockSync,
    createNavigateToDriver,
} from 'src/utils/viewObjectUtils';

interface ObjectViewOptionsMenuProps {
    readonly objectDetail: GetObjectResponse;
    readonly variant?: 'primary' | 'secondary';
    readonly showIcon?: boolean;
}

export function ObjectViewOptionsMenu({
    objectDetail,
    variant = 'primary',
    showIcon = true,
}: ObjectViewOptionsMenuProps) {
    const [id] = usePersistedState(ID_PARAM);

    const { hostname } = window.location;
    const { orgUuid, workspaceUuid, hubCode } = useParams();
    const featureFlags = useFlags();

    const [popoverAnchor, setPopoverAnchor] = React.useState<Element | null>(null);
    const isOpen = Boolean(popoverAnchor);
    const Icon = isOpen ? ArrowUp : ArrowDown;

    const [menuWidth, setMenuWidth] = React.useState(0);

    const openMenu = (event: React.MouseEvent) => {
        setMenuWidth(event.currentTarget.clientWidth);
        setPopoverAnchor(event.currentTarget);
    };

    const closeMenu = React.useCallback(() => {
        setPopoverAnchor(null);
    }, []);

    if (!id || !orgUuid || !workspaceUuid || !hubCode) {
        return null;
    }

    const MENU_OPTIONS = [
        {
            key: 'BlockSync',
            ItemComponent: BlockSyncIcon,
            action: createNavigateToBlockSync(
                hostname,
                orgUuid,
                workspaceUuid,
                hubCode,
                objectDetail.object.blocksync_uuid,
            ),
            shouldRender: canOpenInBlockSync(objectDetail, featureFlags),
        },
        {
            key: 'Driver',
            ItemComponent: DriverIcon,
            action: createNavigateToDriver(hostname, orgUuid, hubCode, workspaceUuid, id),
            shouldRender: canOpenInDriver(objectDetail.object.schema, featureFlags),
        },
    ];

    const options = MENU_OPTIONS.filter((option) => option.shouldRender);

    if (!options.length) {
        return null;
    }

    return (
        <>
            <ButtonGroup
                automation-id="open-in-plot-buttons-view"
                variant="contained"
                onClick={openMenu}
                sx={(theme) => ({
                    flex: '1 1',
                    marginTop: theme.spacing(2),
                    height: '48px',
                    width: '100%',
                })}
            >
                <Button
                    fullWidth
                    color={variant}
                    sx={{ whiteSpace: 'nowrap' }}
                    startIcon={showIcon && <Open fontSize="small" />}
                >
                    {OBJECT_OPEN_IN}
                </Button>
                <Button
                    fullWidth
                    color={variant}
                    sx={{ width: '50px' }}
                    startIcon={<Icon fontSize="large" />}
                />
            </ButtonGroup>
            <Menu
                id="view-object-options-menu"
                anchorEl={popoverAnchor}
                open={isOpen}
                onClose={closeMenu}
                MenuListProps={{
                    sx: { width: menuWidth },
                }}
            >
                <MenuList>
                    {options.map((option) => {
                        const { key, ItemComponent, action } = option;
                        return (
                            <MenuItem key={key} onClick={action}>
                                <ListItemIcon>
                                    <ItemComponent />
                                </ListItemIcon>
                                <ListItemText>{key}</ListItemText>
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </>
    );
}

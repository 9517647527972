import { MapControlTooltip } from '@local/map-viewer/dist/controls/MapControlsTooltip';
import { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { getBoundingBoxCoordsFromLatLngBounds } from '@local/map-viewer/dist/utils/getBoundingBoxCoordsFromLatLngBounds';
import { BoundingBox } from '@local/web-design-system-2';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { PM, Rectangle } from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { DRAW_LAYER, DRAW_LAYER_TOOLTIP } from 'src/strings';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useStyles } from './Control.styles';

interface Props {
    /**
     * Called when the bounding box is set, edited, or removed. If the bounding box is cleared, null is provided.
     */
    onChange: (coords: BoundingBoxCoords | null) => void;
    /**
     * Whether the map should be zoomed to fit the new bounding box when it is set. Default: true.
     */
    fitBounds?: boolean;
}

export function DrawBoundingBoxButton({ onChange, fitBounds = true }: Props) {
    const map = useMap();
    const { classes } = useStyles();

    const handleCreate: PM.CreateEventHandler = (event) => {
        if (event.shape !== 'Rectangle') {
            // In the future we may support polygons
            return;
        }

        const layer = event.layer as Rectangle;

        onChange(getBoundingBoxCoordsFromLatLngBounds(layer.getBounds()));

        if (fitBounds) {
            map.flyToBounds(layer.getBounds(), { duration: 1 });
        }
    };

    const toggleDrawMode = (e: any) => {
        e.stopPropagation();
        if (map.pm.globalDrawModeEnabled()) {
            map.pm.disableDraw();
        } else {
            map.pm.enableDraw('Rectangle', {
                tooltips: false,
            });
        }
    };

    useEffect(() => {
        // Called when draw mode is enabled.
        map.on('pm:drawstart', () => {
            map.pm.getGeomanDrawLayers(true).eachLayer((layer) => {
                map.removeLayer(layer);
            });
            map.dragging.disable();
        });

        // Called when draw mode is disabled.
        map.on('pm:drawend', () => {
            map.dragging.enable();
        });

        // Called when a shape is drawn/finished.
        map.on('pm:create', (event) => {
            if (event.layer) {
                map.dragging.enable();
                handleCreate(event);
            }
        });
    }, [map]);

    return (
        <MapControlTooltip placement="bottom" title={DRAW_LAYER_TOOLTIP}>
            <Button
                variant="contained"
                aria-label={DRAW_LAYER}
                classes={{ root: classes.drawingButton }}
            >
                <Grid
                    gap={1}
                    container
                    wrap="nowrap"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    onClick={toggleDrawMode}
                >
                    <BoundingBox />
                    <Typography className={classes.drawingText}>Draw</Typography>
                </Grid>
            </Button>
        </MapControlTooltip>
    );
}

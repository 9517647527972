import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/customFileEndpoints';
import { ERROR_UPLOADING_FILE } from 'src/strings';
import { FileStatus, UploadStatus } from 'src/types/files';

import { useFileUploadContainer } from './useFileUploadContainer';

export const useFileDragAndDrop = (
    workspaceId: string,
    organisationId: string,
    enabled: boolean = true,
) => {
    const [isHighlighted, setIsHighlighted] = useState(false);
    const { addMessage } = useMessagesContext();
    const [updateFile] = useCustomUpsertFileByPathMutation();
    const { upsertFileStatus } = useFileUploadContainer({ workspaceId });

    const handleUploadPercentage = (percent: number, currentFileStatus: FileStatus) => {
        upsertFileStatus({
            ...currentFileStatus,
            percentCompleted: percent,
        });
    };

    const handleUploadFile = async (file: File, currentFileStatus: FileStatus) => {
        const response = await updateFile({
            workspaceId,
            organisationId,
            filePath: `${file.name}`,
            uploadFile: file,
            updatePercentComplete: (percent) => handleUploadPercentage(percent, currentFileStatus),
        });
        if ('error' in response) {
            upsertFileStatus({
                ...currentFileStatus,
                uploadStatus: UploadStatus.Failed,
                percentCompleted: 100,
            });
            await Promise.reject(response);
        }
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Uploaded,
            percentCompleted: 100,
        });
        return response;
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        if (enabled) {
            event.preventDefault();
            event.stopPropagation();
            if (!isHighlighted) {
                setIsHighlighted(true);
            }
        }
    };

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        if (enabled) {
            event.preventDefault();
            event.stopPropagation();
            setIsHighlighted(true);
        }
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        if (enabled) {
            event.preventDefault();
            event.stopPropagation();
            setIsHighlighted(false);
        }
    };

    const onDrop = async (event: React.DragEvent<HTMLInputElement>) => {
        if (!enabled) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        setIsHighlighted(false);
        const currentFiles = event.dataTransfer.files;
        if (!currentFiles) {
            return;
        }

        const uploadPromises = Array.from(currentFiles).map(async (file) => {
            const fileId = uuid();
            const currentFileStatus: FileStatus = {
                fileName: file.name,
                size: file.size,
                uploadStatus: UploadStatus.Uploading,
                fileId,
                percentCompleted: 0,
            };
            upsertFileStatus(currentFileStatus);
            try {
                return await handleUploadFile(file, currentFileStatus);
            } catch (error) {
                return handleError(error, file, currentFileStatus);
            }
        });

        await Promise.all(uploadPromises);
    };

    const handleError = async (error: unknown, file: File, currentFileStatus: FileStatus) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Failed,
        });
        addMessage({
            message: ERROR_UPLOADING_FILE,
            severity: 'error',
        });
        trackError(`Error: ${error} uploading file "${file.name}"`);
        return Promise.reject(error);
    };

    return { isHighlighted, onDrop, onDragEnter, onDragLeave, onDragOver };
};
